@tailwind base;
@tailwind components;
@tailwind utilities;

.scrollbar::-webkit-scrollbar {
  width: 4px;
}

.scrollbar::-webkit-scrollbar-thumb {
  background: #afaeaea1;
}

.button-primary {
  background-color: #facc11;
  color: white;
  font-weight: bold;
  padding: 0.5rem 1rem;
  border-radius: 0.375rem;
  box-shadow: 3px 3px 5px 0px rgba(0, 0, 0, 0.6);
}

.button-primary:hover {
  background-color: #023047;
  color: #facc11;
}
